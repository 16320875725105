<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler pb-2">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalOffers }} {{ $t('resultsFound') }}
              </div>
            </div>
            <div class="view-options d-flex justify-content-between">
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1 mb-2">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.term"
              :placeholder="$t('search')"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <OpenOfferList
      :item-view="itemView"
      :offers="items"
    />

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalOffers"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <trading-open-offers-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BPagination,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import i18n from '@/libs/i18n';
import router from '@/router';
import {
  onMounted, watch, computed,
} from '@vue/composition-api';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
import TradingOpenOffersSidebar from '@/views/trading/trading-offers/TradingOpenOffersSidebar.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import OpenOfferList from '@/views/apps/components/OpenOfferList.vue';
import { useShopFiltersSortingAndPagination, useShopUi } from './useOpenOffersHandler';

export default {
  directives: {
    Ripple,
  },
  components: {
    OpenOfferList,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BPagination,

    // SFC
    TradingOpenOffersSidebar,
  },
  props: {
    param: {
      type: String,
      default: '',
    },
  },
  watch: {
    param() {
      this.fetchOffers();
    },
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination();

    const {
      itemView, itemViewOptions,
    } = useShopUi();

    const toast = useToast();
    const items = computed(() => store.state.offers.offers);
    const totalOffers = computed(() => store.state.offers.meta.total);

    const fetchOffers = async () => {
      try {
        const routerParam = router.currentRoute.params.param;
        const fetchParam = store.getters['profile/isAdmin'] && routerParam === 'open'
          ? 'verified' : routerParam;
        await store.dispatch('offers/fetch', {
          fetchParam,
          data: {
            per_page: filters.value.perPage,
            page: filters.value.page,
            ...filters.value,
          },
        });
      } catch (err) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('fetchingOffersError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    };

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    watch([filters, sortBy], () => {
      fetchOffers();
    }, {
      deep: true,
    });

    onMounted(async () => {
      await fetchOffers();
    });

    return {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      itemView,
      itemViewOptions,

      fetchOffers,
      items,
      totalOffers,

      mqShallShowLeftSidebar,
    };
  },
};
</script>
